<template>
	<v-container fluid style="margin: 0px; padding: 0px !important">
		<v-row class="backgroundTopo">
			<v-col cols="12" md="6" class="align-center justify-center d-flex flex-column">
				<H1 class="fontTitulo" style="
						font-size: 70px;
						font-weight: 900;
						color: #fff;
						font-family: sofia pro, roboto;
						margin-top: 50px;
						opacity: 100%;
						line-height: 70px;
						padding: 3px 90px;
					">
					TESTE SEU <br />
					NÍVEL DE <br />INGLÊS
				</H1>
			</v-col>

			<v-col cols="12" md="6" class="align-center justify-center d-flex flex-column pdLeft colHg"
				style="margin-top: 23px !important; padding-left: 20px">
				<div class="container-app heightContainer">
					<div class="container-quiz">
						<div class="header-quiz">
							<h1 style="
									font-size: 28px;
									color: #d31f2c;
									font-family: sofia pro, roboto;
								">
								Quer saber o seu nível?
							</h1>
						</div>
						<div class="step-progress" :style="{ width: progress + '%' }"></div>
						<div class="box" v-for="(question, index) in questions.slice(a, b)" :key="index" v-show="quiz">
							<div class="box-question align-center justify-center d-flex flex-column" v-if="b > 1 && b < 10">
								<h2 style="font-family: sofia pro, roboto">
									Questão {{ b - 1 }}/{{ questions.length - 4 }}
								</h2>
								<p class="fontQuest" v-html="question.question" style="
										text-align: left;
										font-family: sofia pro, roboto;
										padding: 0px 30px;
									"></p>
							</div>
							<div v-if="b == 1" class="d-flex justify-center align-center flex-column positionMobile padForm" style="
									background-color: ;
									border-radius: 30px;
									padding: 79px 10px;
									opacity: ;
									position: ;
									width: 100%;
									height: 100%;
								">
								<h1 style="
										font-family: sofia pro, Roboto;
										font-weight: 800;
										font-size: 27px;
										color: #d31f2c;
										line-height: 1;
										text-align: center;
										margin-bottom: 20px;
									">
									Preencha o formulário para continuar
								</h1>
								<v-text-field style="width: 400px" background-color="#fff" outlined v-model="forms.nome"
									label="Nome do aluno" required>
								</v-text-field>
								<v-text-field style="width: 400px" background-color="#fff" outlined v-model="forms.email" label="E-mail"
									required></v-text-field>
								<v-text-field style="width: 400px" v-mask="'(##) #####-####'" background-color="#fff" outlined
									v-model="forms.telefone" label="Telefone" required>
								</v-text-field>
								<v-text-field style="width: 400px" background-color="#fff" outlined v-model="forms.cidade" label="Cidade"
									required>
								</v-text-field>
								<v-text-field style="width: 400px" background-color="#fff" outlined v-model="forms.bairro" label="Bairro"
									required>
								</v-text-field>
								<button style="
										font-family: sofia pro, roboto;
										color: #fff;
										width: 150px;
										height: 45px;
										border-radius: 10px;
										min-height: 45px;
									" @click="skipForm()" :style="next ? 'background-color: rgb(106, 128, 202)' : ''">
									Continuar
								</button>
							</div>
							<div class="box-propositions" v-if="b > 1 && b < 10">
								<ul>
									<li v-for="(proposition, index) in question.propositions" :key="index" class="li"
										@click="selectResponse(proposition, index)" :class="correct ? check(proposition) : ''"
										style="font-family: sofia pro, roboto">
										{{ proposition.props }}
										<div class="fas fa-check" v-if="correct ? proposition.correct : ''"></div>
										<div class="fas fa-times" v-if="correct ? !proposition.correct : ''"></div>
									</li>
								</ul>
							</div>

							<div :class="{ active: b == 10 }"
								class="box-question align-center justify-center d-flex flex-column inative">
								<h2 style="font-family: sofia pro, roboto">
									Questão 9 - Ditado fácil
								</h2>
								<audio ref="playerA" controls="controls">
									<source :src="`https://blogs.abare.cloud/assets/${audio}`" type="audio/ogg" />
									seu navegador não suporta HTML5
								</audio>
								<div style="padding-top: 20px !important">
									<textarea v-model="ditado.facil" required class="ditado" @click="selectTextArea(proposition, index)"
										style="
											height: 45vh;
											width: 30vw;
											border: 2px solid #c4c4c4;
											font-family: Sofia Pro;
										"></textarea>
								</div>
							</div>

							<div :class="{ active: b == 11 }"
								class="box-question align-center justify-center d-flex flex-column inative">
								<h2 style="font-family: sofia pro, roboto">
									Questão 10 - Ditado intermediário
								</h2>
								<audio ref="playerB" controls="controls">
									<source :src="`https://blogs.abare.cloud/assets/${audio}`" type="audio/ogg" />
									seu navegador não suporta HTML5
								</audio>
								<div style="padding-top: 20px !important">
									<textarea v-model="ditado.medio" required class="ditado" @click="selectTextArea(proposition, index)"
										style="
											height: 45vh;
											width: 30vw;
											border: 2px solid #c4c4c4;
											font-family: Sofia Pro;
										"></textarea>
								</div>
							</div>

							<div :class="{ active: b == 12 }"
								class="box-question align-center justify-center d-flex flex-column inative">
								<h2 style="font-family: sofia pro, roboto">
									Questão 11 - Ditado difícil
								</h2>
								<audio ref="playerC" controls="controls">
									<source :src="`https://blogs.abare.cloud/assets/${audio}`" type="audio/ogg" />
									seu navegador não suporta HTML5
								</audio>
								<div style="padding-top: 20px !important">
									<textarea v-model="ditado.dificil" required class="ditado" @click="selectTextArea(proposition, index)"
										style="
											height: 45vh;
											width: 30vw;
											border: 2px solid #c4c4c4;
											font-family: Sofia Pro;
										"></textarea>
								</div>
							</div>
						</div>

						<div class="box-score" v-if="score_show">
							<h2 style="font-family: sofia pro, roboto">Sua pontuação:</h2>
							<h2 style="font-family: sofia pro, roboto">
								{{ score }}/{{ questions.length - 4 }}
							</h2>
							<div class="btn-restart">
								<button id="continuar" :load="loadButton" @click="enviarResposta()"
									style="font-family: sofia pro, roboto">
									Enviar<i class="fas fa-sync-alt"></i>
								</button>
							</div>
						</div>
						<div class="footer-quiz" v-if="score_show == false && (b == 1) == false">
							<div v-if="progress < 100" class="box-button">
								<button v-if="(b == 1) == false" style="font-family: sofia pro, roboto" @click="skipQuestion()"
									:style="next ? 'background-color: rgb(106, 128, 202)' : ''">
									Pular
								</button>

								<button style="font-family: sofia pro, roboto" @click="nextQuestion()"
									:style="!next ? 'background-color: rgb(106, 128, 202)' : ''">
									Próximo
								</button>
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row class="align-center mgFt footer" justify="center" style="
				margin-top: 0px !important;
				margin: -16px;
				font-family: sofia pro, Roboto;
			">
			<v-col style="padding: 50px 0px" cols="6" md="6" xs="6" class="d-flex justify-center align-center flex-column">
				<v-img style="padding: 30px 80px" contain width="100" src="../assets/hulge branco.webp"></v-img>
				<div style="margin-top: 25px">
					<a href="https://www.facebook.com/hugleeducation/" target="_blank">
						<v-icon class="socialIcons" style="color: #fff; padding: 5px" size="25">
							mdi-facebook</v-icon>
					</a>
					<a href="https://www.instagram.com/hugleeducation/" target="_blank">
						<v-icon class="socialIcons" style="color: #fff; padding: 5px" size="25">
							mdi-instagram</v-icon>
					</a>
					<a href="https://www.linkedin.com/company/hugleeducation/?trk=ppro_cprof&originalSubdomain=br" target="_blank">
						<v-icon class="socialIcons" style="color: #fff; padding: 5px" size="25">
							mdi-linkedin</v-icon>
					</a>
				</div>
			</v-col>
			<v-col style="padding: 50px 100px" cols="6" md="6" xs="6" class="flex-column align-center justify-center d-flex">
				<div>
					<a href="mailto:ouvidoria@hugle.com.br">
						<v-btn text style="
								font-family: usual;
								font-weight: 800;
								border-radius: 50px;
								font-size: 13px;

								padding: 30px 30px;
								color: #fff;
								box-shadow: none;
							">
							Email<br />ouvidoria@hugle.com.br
						</v-btn>
					</a>
				</div>
			</v-col>

			<v-row class="align-center" justify="center" style="margin: 5px !important">
				<v-col cols="12" md="6" class="d-flex align-center justify-center">
					<p class="textoMob padTextoFt" style="
							color: #fff;
							font-family: sofia pro, Roboto;
							padding-left: 60px;
						">
						Hugle – Todos direitos reservados. Site atualizado em 2022.
					</p>
				</v-col>
				<v-col cols="12" md="6" class="d-flex align-center justify-center">
					<a href="https://abaregroup.com/" target="_blank">
						<v-img width="85" style="margin-bottom: 20px" src="../assets/abareBranco.webp"></v-img>
					</a>
				</v-col>
			</v-row>
		</v-row>

		<v-dialog v-model="dialog" max-width="500" style="z-index: 99999 !important">
			<v-card class="bgCard">
				<v-card-title style="
						color: #fff;
						font-weight: 500;
						font-size: 20px;
						font-family: Sofia pro;
						word-break: normal;
					">
					O resultado do teste foi enviado!
				</v-card-title>
				<v-card-text style="
						color: #fff;
						font-weight: 500;
						font-family: Sofia pro;
						word-break: normal;
					">Obrigado por ter feito o teste! Entraremos até o próximo dia útil
					para o seu teste oral.</v-card-text>
				<v-card-actions>
					<v-btn style="left: 1px !important" color="#fff" text @click="restartQuiz() && dialog == false">
						Ok
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
import axios from "axios";

export default {
	data() {
		return {
			dialog: false,
			respostas: [],
			forms: { nome: "", email: "", telefone: "", cidade: "", bairro: "" },
			questions: [
				{
					question: "",
					propositions: [
						{ props: "" },
						{ props: "" },
						{ props: "" },
						{ props: "" },
					],
				},
				{
					question:
						" (UFRR 2010)<br> <b>Mary:</b> I am about to fall asleep. I need to wake up! <b><br>Clare:</b> I______you some coffee.",
					propositions: [
						{ props: "will go" },
						{ props: "am going to get" },
						{ props: "will get", correct: true },
						{ props: "am going to get to" },
					],
				},

				{
					question:
						"(UFRR)<br> <b>Billy:</b> After you _____work, will you please drop by the grocery store and pick up some milk and bread.<br> <b>Sally:</b>No problem, I ____up the groceries and be home by 6 o'clock.",
					propositions: [
						{ props: "left – will not pick" },
						{ props: "leaves – will pick" },
						{ props: "left – will not pick" },
						{ props: "leave – will pick", correct: true },
					],
				},
				{
					question:
						"(Ufac/2010)<br> Charles normally ________ water, but now he ________ Coke.",
					propositions: [
						{ props: "drinks; is drinking.", correct: true },
						{ props: "is drinking; drinks." },
						{ props: "was drinking; drinks" },
						{ props: "drink; is drinking." },
					],
				},
				{
					question: "She doesn't ________ anymore.",
					propositions: [
						{ props: "to work out" },
						{ props: "work out", correct: true },
						{ props: "working out" },
						{ props: "works out" },
					],
				},
				{
					question: "Does he speak German?",
					propositions: [
						{ props: "No, they do not" },
						{ props: "No, he is" },
						{ props: "Yes, he does", correct: true },
						{ props: "Yes, I do" },
					],
				},
				{
					question:
						"Do you study every day?<br> _________ I'm a good student!",
					propositions: [
						{ props: "'Yes, I am." },
						{ props: "Yes, I do", correct: true },
						{ props: "No, I not" },
						{ props: "No, I do not." },
					],
				},
				{
					question: "Do you travel a lot? <br> Yes, _______",
					propositions: [
						{ props: "I do.", correct: true },
						{ props: "I am not." },
						{ props: "you do." },
						{ props: "I do not." },
					],
				},
				{
					question: "Do you like bananas?<br> Yes _______",
					propositions: [
						{ props: "I do", correct: true },
						{ props: "I do not" },
						{ props: "I am not" },
						{ props: "Yes, I am" },
					],
				},
				{
					question: "",
					propositions: [
						{ props: "'" },
						{ props: "" },
						{ props: "", correct: true },
						{ props: "" },
					],
				},
				{
					question: " ",
					propositions: [
						{ props: "" },
						{ props: "" },
						{ props: " ", correct: true },
						{ props: "" },
					],
				},
				{
					question: "",
					propositions: [
						{ props: "'" },
						{ props: "" },
						{ props: " ", correct: true },
						{ props: "" },
					],
				},
			],
			a: 0,
			b: 1,
			next: true,
			score_show: false,
			quiz: true,
			score: 0,
			loadButton: false,
			ditado: { facil: " ", medio: " ", dificil: " " },
			correct: false,
			progress: 0,
			audiosArr: [],
		};
	},
	name: "App",
	components: {},
	computed: {
		audio() {
			if (this.audiosArr.length > 0) {
				if (this.b == 10) {
					return this.audiosArr[0].audio;
				}

				if (this.b == 11) {
					return this.audiosArr[1].audio;
				}

				if (this.b == 12) {
					return this.audiosArr[2].audio;
				} else {
					return this.audiosArr[2].audio;
				}
			} else {
				return null;
			}
		},
		isHome() {
			return this.$route.name == "";
		},

		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 0;
				case "sm":
					return 1;
				case "md":
					return 2;
				case "lg":
					return 3;
				case "xl":
					return 4;
				default:
					return 0;
			}
		},
	},
	async mounted() {
		window.scrollTo(0, 0);

		// for (let i = 0; i < this.idAudios.length; i++) {
		// }
		const { data } = await axios.get(
			`https://blogs.abare.cloud/items/audios_hugle?filter=[status][_eq]=published&sort=numero_audio,date_created`
		);
		this.audiosArr = data.data;
		// console.log(this.audiosArr);
	},
	methods: {
		enviarResposta() {
			(this.loadButton = true),
				fetch("https://gestao.abare.cloud/items/leads", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
					},
					body: JSON.stringify({
						id_formulario: 78,
						lead: {
							respostas: this.respostas,
							ditado: this.ditado,
							score: this.score,
							forms: this.forms,
						},
					}),
				})
					.then((response) => response.text())
					.then((data) => {
						window.console.log(data);
						this.loadButton = false;
						this.dialog = true;
						this.enviarEmail();
					});
		},

		enviarEmail() {
			fetch("https://eserver.sanjaworks.com.br/api/send-email", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					templateName: "testeHugle",
					toEmail: "ouvidoria@hugle.com.br",
					subject: "Hugle - Novo teste realizado",
					variables: {
						nome: this.forms.nome,
                telefone: this.forms.telefone,
                email: this.forms.email,
                cidade: this.forms.cidade,
                bairro: this.forms.bairro,
                score: this.score,
				respostas: this.respostas,
				ditado: JSON.stringify(this.ditado, null, 2), // 🔥 Converte objeto em string
					},
				}),
			})
				.then((response) => response.text())
				.then(() => {
					//this.$router.push("obrigado");
					this.r = {};
				});
		},

		selectResponse(e) {
			console.log('chamou')
			this.correct = true;
			this.next = false;
			this.respostas[this.b] = 'Question: ' + this.questions[this.a].question.replace(/(<([^>]+)>)/gi, "") + ' Answer: ' + e.props;
			if (e.correct) {
				this.score++;
			}
		},
		selectTextArea() {
			this.next = false;
		},
		check(status) {
			if (status.correct) {
				return "correct";
			} else {
				return "incorrect";
			}
		},
		nextQuestion() {
			if (this.next) {
				return;
			}
			this.progress = this.progress + 100 / this.questions.length;
			if (this.questions.length - 1 == this.a) {
				this.score_show = true;
				this.quiz = false;
				this.b++;
			} else {
				this.a++;
				this.b++;
				this.correct = false;
				this.next = true;
			}
			// window.console.log(this.$refs);
			if (this.b == 10) {
				this.$refs.playerA[0].load();
			}

			if (this.b == 11) {
				this.$refs.playerB[0].load();
			}

			if (this.b == 12) {
				this.$refs.playerC[0].load();
			}
		},

		skipQuestion() {
			if (!this.next) {
				return;
			}
			this.progress = this.progress + 100 / this.questions.length;
			if (this.questions.length - 1 == this.a) {
				this.score_show = true;
				this.quiz = false;
			} else {
				this.a++;
				this.b++;
			}
			this.$forceUpdate();

			// window.console.log(this.$refs);
			if (this.b == 10) {
				this.$refs.playerA[0].load();
			}

			if (this.b == 11) {
				this.$refs.playerB[0].load();
			}

			if (this.b == 12) {
				this.$refs.playerC[0].load();
			}
		},

		skipForm() {
			if (this.forms.nome === "") {
				alert("Por favor preencha todos os campos");
				return;
			} else if (this.forms.email === "") {
				alert("Por favor preencha todos os campos");
				return;
			} else if (this.forms.telefone === "") {
				alert("Por favor preencha todos os campos");
				return;
			} else {
				this.a++;
				this.b++;
			}
			this.$forceUpdate();
		},

		restartQuiz() {
			Object.assign(this.$data, this.$options.data()); // reset data in vue
		},
	},
};
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 731px) {
	.fontQuest {
		padding: 0px 30px;
	}

	.mgFt {
		margin: 0px !important;
	}

	.columnFtMb {
		flex-direction: column !important;
	}

	.ditado {
		height: 40vh !important;
		width: 76vw !important;
	}

	.padForm {
		padding: 9px 26px !important;
	}

	.pdLeft {
		padding-left: 12px !important;
	}

	.colHg {
		margin-bottom: 89px !important;
	}

	.fontTitulo {
		font-size: 70px !important;
		line-height: 1 !important;
		padding: 3px 0px !important;
	}

	.container-app {
		display: initial !important;
		z-index: 9999 !important;
	}

	.container-quiz {
		height: auto !important;
	}

	.heightContainer {
		max-height: auto !important;
	}

	.box-propositions {
		padding-right: 30px !important;
	}

	.backgroundTopo {
		height: 157vh !important;
	}
}

* {
	font-family: "Sofia pro, Roboto";
	margin: 0;
	letter-spacing: 2px;
}

.backgroundTopo {
	background-image: url("../assets/fundoFranquia.webp");
	background-repeat: no-repeat !important;
	background-size: cover;
	height: 100vh;
	background-attachment: fixed;
	background-color: rgba(255, 255, 255, 0.596);
	background-position: center-top;
}

.bgCard {
	background-image: url("../assets/dialog.webp");
	background-repeat: no-repeat !important;
	background-size: cover;
	width: 500px;
	background-attachment: inherit;
	background-color: rgba(255, 255, 255, 0.596);
	background-position: center-top;
}

.inative {
	display: none !important;
}

.active {
	display: block !important;
}

.container-app {
	display: flex;
	width: 100%;
	height: 100%;
	max-height: auto;
	justify-content: center;
}

.container-quiz {
	display: flex;
	width: 75%;
	height: 90%;
	background-color: white;
	text-align: center;
	flex-flow: column;
	border: 1px solid #e7eae0;
	border-radius: 10px;
	position: inherit;
	top: 35px;
	bottom: 0;
	margin: auto;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.header-quiz {
	display: flex;
	width: 100%;
	height: 10%;
	border-bottom: 1px solid #e7eae0;
	justify-content: center;
	align-items: center;
	background-color: #a4aef079;
	border-radius: 10px 10px 0px 0px;
}

.socialIcons {
	opacity: 0.9 !important;
	color: #ffffff !important;
}

.socialIcons:hover {
	opacity: inherit !important;
	font-size: 46px !important;
	transition: 0.2s;
}

.container-quiz .box {
	display: flex;
	width: 100%;
	height: 80%;
	flex-flow: column;
	margin: auto;
}

.box-propositions {
	margin: auto;
	display: flex;
	width: 100%;
	justify-content: center;
}

ul {
	display: flex;
	width: 80%;
	margin: 0;
	padding: 0;
	flex-flow: column;
}

li {
	list-style: none;
	line-height: 2;
	border: 1px solid #cdd2d2;
	margin-bottom: 20px;
	border-radius: 15px;
	cursor: pointer;
	transition: 0.3s;
}

li:hover {
	/*transform: scale(1.1);*/
	background-color: #e7eae0;
}

li>div {
	float: right;
	margin-top: 7px;
	margin-right: 7px;
	color: white;
}

.check {
	color: rgb(74, 219, 74);
}

.close {
	color: rgb(240, 117, 100);
}

.footer-quiz {
	display: flex;
	width: 100%;
	height: 20%;
	justify-content: center;
	border-top: 1px solid #e7eae0;
	background-color: #a4aef079;
	border-radius: 0px 0px 10px 10px;
}

.box-button {
	display: flex;
	width: 100%;
}

.footer-quiz .box-button button {
	width: 150px;
	height: 30px;
	outline: none;
	border: 0;
	color: white;
	font-size: 18px;
	cursor: pointer;
	border-radius: 15px;
	margin: auto;
	margin-bottom: 10px;
	letter-spacing: 2px;
	background-color: #a09f9ff5;
}

li.correct {
	border: 1px solid rgb(74, 219, 74);
	background-color: rgb(74, 219, 74);
	color: white;
	font-weight: 600;
}

li.incorrect {
	border: 1px solid rgb(240, 117, 100);
	background-color: rgb(240, 117, 100);
	color: white;
	font-weight: 600;
}

.box-score {
	display: flex;
	width: 100%;
	height: 70%;
	flex-flow: column;
}

.box-score h2 {
	margin-top: 40px;
}

i {
	display: none;
	color: white;
}

.step-progress {
	display: flex;
	width: 100%;
	height: 5px;
	background-color: rgb(106, 128, 202);
	transition: 0.5s;
}

.btn-restart {
	display: flex;
	width: 100%;
	height: auto;
	justify-content: center;
	margin-top: 50px;
}

.btn-restart button {
	width: 150px;
	height: 35px;
	outline: none;
	border: 0;
	background-color: rgb(106, 128, 202);
	color: white;
	font-size: 18px;
	cursor: pointer;
	border-radius: 15px;
	margin: auto;
	margin-bottom: 10px;
	letter-spacing: 2px;
}

.footer {
	background: linear-gradient(148deg,
			rgba(167, 18, 19, 1) 0%,
			rgba(223, 57, 57, 1) 100%,
			rgba(255, 255, 255, 1) 100%) !important
}

.next {
	background-color: rgb(106, 128, 202);
}

@media screen and (max-width: 900px) {
	.container-quiz {
		width: 60%;
	}
}

@media screen and (max-width: 720px) {
	.container-quiz {
		width: 100%;
		position: relative !important;
	}

	.footer-quiz .box-button button {
		width: 100px;
	}
}
</style>
