<template>
    <v-container fluid>
      <v-row class="backgroundTopo  d-flex align-center">
        <v-col>
          <H1 class="textoTopo" style="font-size:70px;
              font-weight: 900;
                color:#fff;
              margin-top:100px;
              opacity:50%;
              line-height: 80px;
              padding:3px 90px"> Cursos, modalidades & Metodologia </H1>
        </v-col>
      </v-row>
  
      <v-row  style="padding: 0px 0px; margin-bottom:40px">
        <v-col cols="12" md="12" class=" flex-column d-flex padMobile align-center justify-center padLeftMobile" data-aos="fade-up" data-aos-duration="2500"
         style="padding:20px 90px">
         <div style="background-color:#1B3765; border-radius:20px; padding:10px; margin-bottom:20px">
          <h1 class="fontSzMobile align-center flex-column d-flex justify-center" style="
          margin-top:0px; padding:0px 10px; font-size:50px; color:#fff">HUGLE FOR ADULTS</h1>
        </div>
          <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; max-width:1200px">O
            <span style="font-weight:800">HUGLE FOR ADULTS,</span> 
            é um curso <span style="font-weight:800">INTENSIVO</span> para adultos a partir de 17 anos, foi criado com o propósito de ensinar inglês da mesma forma que você
            aprendeu o seu idioma materno, permitindo que você <span style="font-weight:800">PENSE E FALE EM INGLÊS</span> da mesma forma que você faz em português.
            O foco é <span style="font-weight:800">treinar</span> a mente a pensar em inglês desde a primeira aula, utilizando técnicas de associação a imagens,
            repetição e situações do dia a dia.  </p>
        </v-col>
      </v-row>

      <v-row>
      <v-col cols="12" md="6" class="align-center justify-center d-flex padExpansion flex-column align-self-start pdLeft" style="padding-left:80px"> 
                <v-expansion-panels accordion style="max-width:800px; border-radius:20px; ;">
                    <v-expansion-panel style="background-color:#fff"
                        v-for="(primeiro,i) in fac"
                        :key="i">
                        <v-expansion-panel-header style="color:#0B2128; font-family:Poppins; font-weight:500; font-size:20px; text-align: justify;" v-html="primeiro.pergunta"></v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column" style="color:#0B2128; padding:5px 20px; font-family:Poppins; font-size:15px; text-align: justify;" v-html="primeiro.resposta">
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            <p style="color:#0B2128; font-weight:800; padding-top:30px; font-size:20px; line-height: 1;">
                Mais informações, preencha o formulário e solicite um atendimento personalizado
                para conhecer o melhor método de ensino do mercado.
            </p>
      </v-col>
      <v-col cols="12" md="6" style="padding:0px !important"
                class="d-flex justify-center flex-column align-center">
                <div class="d-flex justify-center flex-column positionMobile" style="background-color: #1e3e87d4;
                 border-radius: 30px;
                 padding: 80px 50px;
                 opacity:;  
                 width: 500px;
                 height: 525px;">
                  <h1
                  style="font-family: sofia pro, Roboto; font-weight: 500; font-size: 25px; color: #fff; line-height: 1; text-align: center ; margin-bottom:20px;">
                  Agende agora uma AULA experimental</h1>
                    <v-text-field background-color="#fff" style="border-radius:20px" outlined v-model="contato.nome"
                        label="Nome" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" style="border-radius:20px" outlined v-model="contato.email"
                        label="E-mail" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" style="border-radius:20px" v-mask="'(##) #####-####'" outlined
                        v-model="contato.telefone" label="Whatsapp" required>
                    </v-text-field>
                    <v-btn :disabled="loadButton" :load = "loadButton" @click="enviarFormulario" class="mr-4" type="button" height="50px" style="background-color: #fff; color: #1E3E87; 
            font-family: Sofia pro, Roboto; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 25px; margin-right: 0px !important;"> Enviar
                    </v-btn>
                </div>
            </v-col>
      </v-row>

      <v-row class="" style="padding: 0px 0px; margin-bottom:40px">
        <v-col cols="12" md="12" class=" flex-column d-flex padMobile align-center justify-center padLeftLousa" data-aos="fade-up" data-aos-duration="2500"
         style="padding:20px 90px">
         
          <h1 class="fontSzMobile align-center flex-column d-flex justify-center" style="
            margin-top:0px; padding:0px 10px; font-size:50px; color:#D31F2C">HUGLE FOR KIDS</h1>
    
            <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; max-width:1200px">O curso
            <span style="font-weight:800">HUGLE FOR KIDS,</span> 
            para crianças de 7 à 11 anos, trabalha com um método que proporciona às crianças o aprendizado por meio de atividades lúdicas, semelhante à forma como a criança aprende a
            sua língua materna, auxiliando no <span style="font-weight:800">desenvolvimento da motricidade fina, concentração, interação,</span> além de adquirir mais
            <span style="font-weight:800">vocabulário, fala, escrita e leitura</span> em inglês.</p> 
            
            <div class="align-center justify-center d-flex flex-column" style="
            border: 10px solid #C17D11;
            background: linear-gradient(177deg, rgba(228,13,13,1) 0%, rgba(0,50,5,1) 0%, rgba(1,74,0,1) 100%);
            border-radius: 20px;
            padding: 20px;
            margin-top: 40px;
            max-width: 1084px">  
            <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; color:#fff">
                O aprendizado do idioma inglês no curso <span style="font-weight:800">HUGLE FOR KIDS</span>
                é feito de maneira interativa e dinâmica, com temáticas infantis e repleto de brincadeiras.
                Assim, proporcionamos aos alunos um ambiente familiar e divertido.
            </p>
            <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; color:#fff">
                <span style="font-weight:800">A infância é a melhor fase das nossas vidas.</span> Aprender nessa fase é importante, mas para isso o aprendizado deve ser divertido.
            </p> 
            <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; color:#fff">O objetivo do curso de inglês
                <span style="font-weight:800">HUGLE FOR KIDS</span> é que seu filho  <span style="font-weight:800">aprenda de maneira divertida e sem deixar de ser criança.</span>
            </p> 
            <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; color:#fff">São 2 aulas semanais de 50 minutos
                cada, além de uma plataforma de atividades extras que pode estudar de qualquer lugar a qualquer hora.
            </p> 
            <v-img contain style="max-width:150px" src="../assets/kid.png"></v-img>
            </div>    
        </v-col>
      </v-row>
      
      <v-row class="" style="padding: 0px 0px; margin-bottom:40px">
        <v-col cols="12" md="12" class=" flex-column d-flex padMobile align-center justify-center padLeftMobile" data-aos="fade-up" data-aos-duration="2500"
         style="padding:0px 90px">
         <div style="background-color:#D31F2C; border-radius:20px; padding:10px; margin-bottom:20px">
          <h1 class="fontSzMobile align-center flex-column d-flex justify-center" style="
            margin-top:0px; padding:0px 10px; font-size:50px; color:#fff">HUGLE FOR TEENS</h1>
         </div>
            <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; max-width:1200px">
                Nunca ficou tão evidente a importância do domínio do idioma inglês, principalmente para essa nova geração de adolescentes que em alguns anos estarão
                em busca de garantir o emprego dos sonhos ou até mesmo viajar por esse mundão a fora.
            </p>
            <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; max-width:1200px">
                Vários especialistas vêm alertando que as duas principais habilidades para esse novo mundo é saber tecnologia e falar inglês. 
            </p> 
            <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; max-width:1200px">
                Empresas brasileiras de tecnologia vêm perdendo seus principais colaboradores para empresas norte americanas que chegam aqui e oferecem de 20 a 40 mil
                reais de salário para trabalho em regime de home office. Basta ter conhecimento em desenvolvimento de sistemas, conhecimento em tecnologia e lógico,
                TER O DOMÍNIO DO IDIOMA INGLÊS. 
            </p>  
        </v-col>
      </v-row>
      
      <v-row class="wallbook" style="margin-bottom:50px">
        <v-col cols="12" md="6" class="align-self-end align-center pdLeft" style="padding-left:50px; padding-bottom:0px">
            <v-img contain style="max-width:500px;" src="../assets/criancaPapoReto.png"></v-img>
        </v-col>
        <v-col cols="12" md="6" class=" align-self-center d-flex flex-column">
            <p style="color:#1B3765; font-family: Sofia Pro; font-size:22px; font-weight:500">Aprender inglês na adolescência é muito mais fácil, então nosso conselho é:</p>
            <p style="color:#E13F4A; font-family: Sofia Pro; font-size:40px; font-weight:800; line-height: 1;">BOTA ESSE MENINO OU ESSA MENINA HOJE MESMO PARA APRENDER INGLÊS!</p>
            <p style="color:#1B3765; font-family: Sofia Pro; font-size:22px; font-weight:600">Nosso método de imersão acontece com turmas reduzidas em um ambiente seguro com profissionais
                preparados para tornar a experiência de falar inglês muito mais leve e divertida para seus filhos. 
            </p>
            <p style="color:#E13F4A; font-family: Sofia Pro; font-size:40px; font-weight:800; line-height: 1;">VEM PRA HUGLE HOJE MESMO</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" class="align-center align-self-end d-flex justify-center flex-column pdLeft" style="padding-left:80px; padding-bottom:0px">
            <h1 style="color:#1B3765; font-weight:800; font-family: Sofia Pro; font-size:40px; line-height: 1">
                Como funcionam as aulas do curso <span style="color:#E13F4A">HUGLE FOR TEENS?</span>
            </h1>
            <v-img class="align-self-end" contain style="max-height:453px" src="../assets/duvida.png"></v-img>
        </v-col>
        <v-col class="align-self-end" style="padding-bottom:0px">
            <div style="background-color:#1B3765; border-radius:20px; padding:30px; margin-bottom:0px" class="">
                <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; color:#fff">
                    <span style="font-weight:800">São 3 aulas semanais de 50 minutos cada da seguinte forma: </span>
                </p> 
                <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; color:#fff; font-weight: 600;">
                    - 2 aulas semanais online ao vivo, em horários fixos, com professores reais no mesmo formato das aulas presenciais, porém na tela do seu computador, tablet ou
                    smartphone. Também temos aulas presenciais. (Essas aulas são obrigatórias para cumprir o programa de ensino); 
                </p> 
                <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; color:#fff">
                  - 1 aula extra ao vivo às sextas-feiras com temas variados: conversação livre, música, gramática, trechos de filmes, etc.
                </p>
                <p class="" style="font-family: Sofia pro, Roboto; padding: 10px 10px; margin-bottom:0px; font-size:20px; color:#fff; font-weight: 800;">
                    Aqui na HUGLE seu filho ou filha se comunicará em inglês em menos de 18 meses!  
                </p> 
            </div>
        </v-col>
      </v-row>

      <v-row
        style="background: linear-gradient(148deg, rgba(167,18,19,1) 0%, rgba(223,57,57,1) 100%, rgba(255,255,255,1) 100%); height: 35px;">
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="align-center justify-center d-flex">
            <h1 style="font-family: Sofia Pro; color:#E13F4A; font-weight:800; font-size:50px; line-height: 1;">MODALIDADES DOS CURSOS</h1>
        </v-col>
        <v-col cols="12" class="align-center justify-center d-flex">
            <v-carousel 
            hide-delimiters
            style="max-width:800px; border-radius:25px"
            >
                <v-carousel-item
                v-for="(item,i) in items"
                :key="i"
                :src="item.src"
                style="max-width:800px; "
                reverse-transition="fade-transition"
                transition="fade-transition"
                >
            <v-col class="align-center justify-center d-flex flex-column paddingP" style="padding:20px 100px">
            <h1 class="fontCarrossel" style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px; line-height:1; margin-bottom:50px">{{item.titulo}}</h1>
            <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px; text-align: justify;" v-html="item.texto"></p>
            </v-col>
            </v-carousel-item>
            </v-carousel>
        </v-col>
      </v-row>

    <v-row>
      <v-col cols="12" md="6" class="d-flex   pdLeft" style="padding-left:80px; padding-bottom:0px">
        <h1 class="fontSzMobile" style="font-family: Sofia Pro; color:#1E3E87; font-weight:800; font-size:50px">METODOLOGIA</h1>
      </v-col>
      <v-col cols="12" md="12" class="d-flex justify-center flex-column">
        <p class="paddingP" style="font-family: Sofia pro, Roboto; padding: 5px 70px; margin-bottom:0px; font-size:20px">
          O objetivo da nossa metodologia é <span style="font-weight:800">ensinar inglês a partir do próprio idioma inglês.</span>
        </p>
        <p class="paddingP" style="font-family: Sofia pro, Roboto; padding: 5px 70px; margin-bottom:0px; font-size:20px">
          Nossa forma de ensino tem o propósito de ensinar inglês <span style="font-weight:800">da mesma maneira que você aprendeu o seu idioma materno, sem utilizar tradução.</span>
        </p>
        <p class="paddingP" style="font-family: Sofia pro, Roboto; padding: 5px 70px; margin-bottom:0px; font-size:20px">
          O foco é <span style="font-weight:800">treinar a mente a pensar em inglês desde a primeira aula</span> utilizando desde técnicas de associação a imagens e incentivando o aluno
          <span style="font-weight:800"> a praticar a comunicação a partir do primeiro dia de aula,</span> através das experiências e situações do seu dia a dia.
        </p>
        <p class="paddingP" style="font-family: Sofia pro, Roboto; padding: 5px 70px; margin-bottom:0px; font-size:25px; color:#D31F2C; font-weight:800; line-height:1">
          Quando crianças, existem 4 PILARES essenciais que <br>nos tornam fluentes no idioma do país que nascemos. São eles: 
        </p>
      </v-col>
    </v-row>

    <v-row>
        <v-col cols="6" md="3" class="align-center justify-center d-flex align-self-end" style="">
            <v-img @click="show1 = true" contain style="max-height:300px; cursor:pointer " src="../assets/imersao.png"></v-img>
        </v-col>
        <v-col cols="6" md="3" class="align-center justify-center d-flex align-self-end">
            <v-img @click="show2 = true" contain style="max-height:328px; cursor:pointer" src="../assets/ordem.png"></v-img>
        </v-col>

        <br v-if="intBreakPoint < 2">

        <v-col cols="6" md="3" class="align-center justify-center d-flex align-self-end">
            <v-img @click="show3 = true" contain style="max-height:300px; cursor:pointer" src="../assets/associacao.png"></v-img>
        </v-col>
        <v-col cols="6" md="3" class="align-center justify-center d-flex align-self-end">
            <v-img @click="show4 = true" contain style="max-height:330px; cursor:pointer" src="../assets/repeticao.png"></v-img>
        </v-col>
    </v-row>

    <v-row class="fundoSala">
      <v-col cols="12" md="7" class="justify-center d-flex flex-column">
        <h1 class="pdLeft" style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px; padding-left:80px">Na prática</h1>
        <p class="paddingP" style="font-family: Sofia pro, Roboto; padding: 5px 80px; margin-bottom:0px; font-size:20px; color: #fff; line-height:1">
          A comunicação é de maneira inteligente e as aulas são 100% em inglês desde o primeiro dia, sem o uso de tradução em sala para
          acelerar o processo de aprendizado. Tanto o vocabulário como a gramática são introduzidos de maneira programada.</p>
        <p class="paddingP" style="font-family: Sofia pro, Roboto; padding: 5px 80px; margin-bottom:0px; font-size:20px; color: #fff; line-height:1">
          O professor conduz a aula falando na mesma velocidade de um nativo, a fim de que o aluno aprenda a entender o inglês falado na sua velocidade natural.</p>
        <p class="paddingP" style="font-family: Sofia pro, Roboto; padding: 5px 80px; margin-bottom:0px; font-size:20px; color: #fff; line-height:1">
          Primeiro o aluno OUVE, ENTENDE e FALA, depois começa a LER o que já entende, para só então passar a ESCREVER. Tudo isso com o apoio da GRAMÁTICA.</p>
        <p class="paddingP" style="font-family: Sofia pro, Roboto; padding: 5px 80px; margin-bottom:0px; font-size:20px; color: #fff; line-height:1">
          O objetivo do método é proporcionar a confiança necessária para se comunicar em qualquer situação, permitindo PENSAR NO IDIOMA da mesma forma que
          pensa em português,.</p>
      </v-col>
      <v-col cols="12" md="5" class="align-center align-self-end d-flex" style="padding-bottom:0px">
        <v-img v-if="intBreakPoint > 2" contain style="max-width:520px" src="../assets/apontaEsq.png"></v-img>
        <v-img v-if="intBreakPoint < 2" contain style="max-height:400px;" src="../assets/apontaCim.png"></v-img>
      </v-col>
    </v-row>

    <v-row style="margin: 30px 0px">
      <v-col cols="12" md="6" class="justify-center d-flex padExpansion flex-column align-self-start pdLeft" style="padding-left:80px"> 
        <h1 style="font-family: Sofia Pro; color:#D31F2C; font-weight:800; font-size:45px; line-height: 1;">Prática de conversação o tempo todo:</h1>
        <p style="font-family: Sofia pro, Roboto; margin-bottom:0px; font-size:20px; color: #000; line-height:1">
          A prática das aulas acontece através de uma conversação guiada onde o professor faz perguntas e o aluno responde com o auxílio e correção imediata
          do professor sempre que necessário.
        </p>
        <p style="font-family: Sofia pro, Roboto; margin-bottom:0px; font-size:20px; color: #000; line-height:1">
          Este formato faz com que o aluno não tenha tempo para traduzir, mesmo que tente. E essa é exatamente a ideia,
          uma vez que quanto mais tradução, mais lento fica o aprendizado.
        </p>
        <br>
        <p style="font-family: Sofia pro, Roboto; margin-bottom:0px; font-size:25px; color: #000; line-height:1; font-weight:800">
          Ficou interessado? Entre em contato conosco preenchendo o formulário!
        </p>
      </v-col>
      <v-col cols="12" md="6" style="padding:0px !important"
                class="d-flex justify-center flex-column align-center">
                <div class="d-flex justify-center flex-column positionMobile" style="background-color: #1e3e87d4;
                 border-radius: 30px;
                 padding: 80px 50px;
                 opacity:;  
                 width: 500px;
                 height: 525px;">
                    <v-text-field background-color="#fff" style="border-radius:20px" outlined v-model="contato.nome"
                        label="Nome" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" style="border-radius:20px" outlined v-model="contato.email"
                        label="E-mail" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" style="border-radius:20px" v-mask="'(##) #####-####'" outlined
                        v-model="contato.telefone" label="Whatsapp" required>
                    </v-text-field>
                    <v-btn :disabled="loadButton" :load = "loadButton" @click="enviarFormulario" class="mr-4" type="button" height="50px" style="background-color: #fff; color: #1E3E87; 
            font-family: Sofia pro, Roboto; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 25px; margin-right: 0px !important;"> Enviar
                    </v-btn>
                </div>
            </v-col>
      </v-row>


    <v-dialog v-model="show1" max-width="600px" style="border-radius:20px">
      <v-col cols="12" md="12" class="justify-center d-flex flex-column"
        style="background: linear-gradient(148deg, rgba(167,18,19,1) 0%, rgba(223,57,57,1) 100%, rgba(255,255,255,1) 100%);
        max-width:600px; padding:30px; border-radius:20px">
        <div style="justify-content:right" class="d-flex ">
          <v-icon @click="(show1 = false)" style="color:#fff">mdi-close</v-icon>
        </div>
        <h1 style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px; text-align: center">Imersão</h1>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px"> Ou seja, o tempo de exposição ao idioma, no nosso caso, português.</p>
      </v-col>
    </v-dialog>
    <v-dialog v-model="show2" max-width="600px" style="border-radius:20px">
      <v-col cols="12" md="12" class="justify-center d-flex flex-column"
        style="background: linear-gradient(148deg, rgb(0, 21, 28) 0%, rgb(4 79 171) 100%, rgb(255, 255, 255) 100%);
        max-width:600px; padding:30px; border-radius:20px">
        <div style="justify-content:right" class="d-flex ">
          <v-icon @click="(show2 = false)" style="color:#fff">mdi-close</v-icon>
        </div>
        <h1 style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px;
        line-height: 1; padding-bottom:30px">Ordem natural do aprendizado das habilidades:</h1>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px">
          Compreender o que ouve e começar a falar, muito antes de ler, escrever ou
          estudar regras gramaticais na escola.</p>
      </v-col>
    </v-dialog>
    <v-dialog v-model="show3" max-width="600px" style="border-radius:20px">
      <v-col cols="12" md="12" class="justify-center d-flex flex-column"
        style="background: linear-gradient(148deg, rgba(167,18,19,1) 0%, rgba(223,57,57,1) 100%, rgba(255,255,255,1) 100%);
        max-width:600px; padding:30px; border-radius:20px">
        <div style="justify-content:right" class="d-flex ">
          <v-icon @click="(show3 = false)" style="color:#fff">mdi-close</v-icon>
        </div>
        <h1 style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px; padding-bottom:30px; text-align: center">Associação</h1>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px"> ou seja, a comunicação ocorre pelo processo da associação, sem o uso
          de tradução. Quando associamos o aprendizado à uma imagem ou situação que aconteceu, nosso cérebro memoriza muito mais rápido. Quando traduzimos,
          acabamos caindo na armadilha de decorar</p>
      </v-col>
    </v-dialog>
    <v-dialog v-model="show4" max-width="600px" style="border-radius:20px">
      <v-col cols="12" md="12" class="justify-center d-flex flex-column"
        style="background: linear-gradient(148deg, rgb(0, 21, 28) 0%, rgb(4 79 171) 100%, rgb(255, 255, 255) 100%);
        max-width:600px; padding:30px; border-radius:20px">
        <div style="justify-content:right" class="d-flex ">
          <v-icon @click="(show4 = false)" style="color:#fff">mdi-close</v-icon>
        </div>
        <h1 style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px;
        line-height: 1; padding-bottom:30px">Repetição com correção:</h1>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px">
          nossos familiares próximos falam, falam, falam ao nosso redor, e nós começamos a repetir. Vamos cometendo erros e eles vão nos corrigindo,
          o que eventualmente nos leva a falar corretamente.</p>
      </v-col>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    
    </v-container>
  </template>
  
  <script>
  export default {
    data: () => ({
      loadButton: false,
      tab: null,
      tab2: null,
      tab3: null,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
  
      items: [
          {
            titulo: 'HUGLE INTENSIVE',
            texto: 'O curso HUGLE INTENSIVE tem a duração completa de 2,5 anos (nível avançado), com aulas diárias (segunda à sexta) com duração de 50 minutos cada. Lembrando que em aproximadamente um ano nossos alunos já conseguem se comunicar 100% em inglês. ',
            src: require('../assets/calendario.png'),
          },
          {
            titulo: 'HUGLE VIP COURSE',
            texto: 'São aulas individuais, dupla ou trio, aplicadas de forma personalizada de acordo com as necessidades específicas e disponibilidade do aluno. ',
            src: require('../assets/aula.png'),
          },
          {
            titulo: 'HUGLE FOR BUSINESS',
            texto: 'São aulas de inglês avançado com linguagem e termos exclusivos do mundo dos negócios. ',
            src: require('../assets/negocios.png'),
          },
          {
            titulo: 'HUGLE IN COMPANY',
            texto: 'São aulas ministradas dentro das empresas de forma presencial ou online ao vivo, com turmas formadas exclusivamente dos colaboradores, firmando uma parceria com benefícios especiais. ',
            src: require('../assets/aulaEmpresa.png'),
          },
          {
            titulo: 'HUGLE CONVERSATION',
            texto: 'São aulas de inglês avançado com temas atuais e debates entre os alunos, proporcionando situações em que a pronúncia e gramática são constantemente corrigidas e expressões idiomáticas são aprendidas.',
            src: require('../assets/conversando.png'),
          },
      ],

      fac: [
            {
                pergunta:'1) Quanto tempo levarei para aprender a falar inglês na HUGLE? ',
                resposta:'No curso HUGLE INTENSIVE para adultos, o aprendizado é 4x mais rápido que nas escolas tradicionais, sendo aulas todos os dias de segunda à sexta-feira (5 aulas semanais de 50 minutos cada) para que você viva uma completa imersão no idioma inglês. <br> As aulas ocorrem de forma prática, rápida e dinâmica em turmas reduzidas através de aulas online ao vivo ou presenciais com foco 100% na comunicação desde o primeiro dia. Essa prática permite que o aprendizado aconteça muito mais rápido e de forma consistente levando o aluno do ZERO para uma conversa 100% em inglês em aproximadamente 12 meses.',
            },
        
            {
                pergunta:'2) Como irei aprender inglês na HUGLE? ',
                resposta:'A prática das aulas acontece através de uma conversação guiada onde o professor faz perguntas e o aluno responde com o auxílio e correção imediata do professor sempre que necessário. Este formato faz com que o aluno não tenha tempo para traduzir, mesmo que tente, o que é fundamental para o sucesso do aprendizado. Na HUGLE, ouvir e falar são prioridades, pois as aulas são 100% em inglês desde o primeiro dia onde você estará constantemente ouvindo e falando, para você adquirir a confiança necessária para usar o inglês em qualquer situação. <br><br> A gramática é aplicada de forma prática usando como exemplos frases do cotidiano e situações do dia a dia. Desta forma fica mais simples e natural aprender!',
            },
            {
                pergunta:'3) Como funcionam as aulas todos os dias?  ',
                resposta:'As aulas acontecem de segunda à sexta-feira, 50 minutos cada, e ocorrem da seguinte forma: <br><br> -  4 aulas semanais online ao vivo ou presencial, em horários fixos, de segunda à quinta para cumprir a programação do curso;<br> - Aulas extras às sextas-feiras Online ao vivo com temas variados onde o aluno pratica a conversação livre, aula com música, trechos de filmes, etc. (aulas não obrigatórias na grade);<br><br> - A cada período de aproximadamente 30 dias acontece um rodízio de professores: Além de o aluno sair da zona de conforto, terá acesso a vários sotaques diferentes, americano, britânico, canadense, australiano entre outros...',
            },
            {
                pergunta:'4) Por que aprender inglês é primordial? ',
                resposta:'Menos de 5% da população brasileira fala fluentemente o idioma inglês. Aprender inglês e saber falar o idioma lhe dará grandes vantagens competitivas aumentando as oportunidades de acesso as inovações mais rápidas e assim ganhar vantagem competitiva no mercado.',
            },
            {
                pergunta:'5) Quanto custa o curso de inglês da HUGLE?  ',
                resposta:'Os planos de investimento podem variar de acordo com a modalidade que você escolher. Assim que preencher o formulário solicitando o atendimento, entraremos em contato para explicar nossos planos de investimento disponível.',
            },
        ],
  
      snackbar: false,
      text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
      contato: { nome: "", email: "", telefone: "" },
      timeout: 2000,
    }),
  
    methods: {
      enviarFormulario() {
      if ((this.contato.nome === "")) { 
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.email === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.telefone === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else{
      this.loadButton = true,
      this.loading = true,
        fetch("https://gestao.abare.cloud/items/leads", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ id_formulario: 156, lead: this.contato }),
        })
          .then((response) => response.text())
          .then(() => {
            // window.console.log(data);
            // alert("contato enviado com sucesso");
            this.snackbar = true;
          });
    }
  }
    },
  
    mounted() {
          window.scrollTo(0, 0);
      },
  
    computed: {
      isHome() {
        return this.$route.name == "";
      },
  
      intBreakPoint() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 0;
          case "sm":
            return 1;
          case "md":
            return 2;
          case "lg":
            return 3;
          case "xl":
            return 4;
          default:
            return 0;
        }
      },
    },
  }
  </script>
  
  <style scoped>
  @media (min-device-width: 340px) and (max-device-width: 731px) {
  
    .padMobile {
      padding: 50px 0px !important;
  
    }
    .backgroundTopo {
    background-position: center !important;
    }
    .fontCarrossel{
      font-size:42px !important;
    }

    .pdLeft{
      padding-left:5px !important;
    }
    .positionMobile {
    position: relative !important;
    right: 0 !important;
    top: initial !important;
    padding: 50px 30px !important;
    width: 360px !important;
  }

  
    .btnMobile {
      width: 320px !important;
      height: 75px !important;
      border-radius: 18px;
      margin-right: 10px;
    }
  
    .padLeftMobile {
      padding-left: 10px !important;
    }
    .padLeftLousa{
      padding-left: 0px !important;
    }
  
    .textoTopo {
      font-size: 50px !important;
      font-weight: 900 !important;
      color: rgb(255, 255, 255) !important;
      margin-top: 100px !important;
      opacity: 0.5 !important;
      line-height: 1 !important;
      padding: 3px 3px !important;
    }

    .paddingP{
      padding: 5px 15px !important; 
    }
  
    .fontSzMobile {
    font-size: 30px !important;
    line-height: 1 !important;
    padding-left:15px !important;
    font-weight: 800 !important;
  }

  
  }
  
  .fundoHugle {
    background-image: url("../assets/capahugle.webp");
    background-repeat: no-repeat !important;
    background-size: cover;
    background-attachment: fixed;
    background-position: center-top;
  }
  
  .backgroundTopo {
    background-image: url("../assets/cursosModalidades.png");
    background-repeat: no-repeat !important;
    background-size: cover;
    height: 450px;
    background-attachment: fixed;
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center-top;
  }

  .wallbook {
    background-image: url("../assets/wallbook.png");
    background-repeat: no-repeat !important;
    background-size: cover;
    height: auto;
    /* background-attachment: fixed; */
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center;
  }
  .fundoSala {
    background-image: url("../assets/fundoSala.png");
    background-repeat: no-repeat !important;
    background-size: cover;
    height: auto;
    /* background-attachment: fixed; */
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center;
  }
  
  </style>