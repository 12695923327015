<template>
  <v-container fluid>
    <v-row key="row4" class="pteste d-flex fillHeight pt5 padMobile justify-center" style=" 
            min-height: 400px; 
            padding: 50px 90px" align-center id="4pt">
             <v-col cols="12" md="6"></v-col>
      <v-col cols="12" md="6" class="" style="">
        <h1 class="textoTopo textoTopoMobile" >
          SAC HUGLE</h1>
          <p class="subtituloM" style="font-size:15px; font-weight: 500; color:#fff; margin-left: 165px; opacity: 100%">Serviço de Atendimento ao Cliente</p>
      </v-col>
    </v-row>

    <v-row class="padMobile" style="padding-top: 35px; padding-left: 260px">
      <v-col class="justify-center align-center" cols="12" md="12" data-aos="fade-right" data-aos-duration="2500">
        <p style="font-family: sofia pro, Roboto;
                    font-weight: 500;
                    font-size: 22px;
                    color: #071c4a;
                    line-height: 1;
                    margin-bottom:-5px;">
          Para atendermos sua solicitação, preencha todos os campos abaixo. <br>
            Após o envio deste formulário o tempo de resposta é de até 2 dias úteis.
          </p><br>
      </v-col>
    </v-row>

    <v-row class="justify-center " style=" 
        padding: 0px 0px;" align-center id="4pt">

      <v-col cols="12" md="5" data-aos="fade-up" data-aos-duration="2500">
        <div class="positionMobile  justify-center d-flex flex-column" style=";
                border-radius: 10px;
                margin-top: 0px;
                margin-bottom:0px;">
          <v-text-field background-color="#fff" outlined v-model="contato.nome" label="Nome completo" required>
          </v-text-field>
          <v-text-field background-color="#fff" outlined v-model="contato.email" label="E-mail" required></v-text-field>
          <v-select style="color: #fff; background-color: #fff; height: 55px; margin-bottom: 32px" :items="items" v-model="contato.unidade"
            label="Unidade" outlined></v-select>
          <v-text-field v-mask="'(##) #####-####'" background-color="#fff" outlined v-model="contato.telefone"
            label="Telefone" required>
          </v-text-field>
          <v-textarea background-color="#fff" style="height:" outlined v-model="contato.mensagem"
            label="Comentário ou solicitação" required>
          </v-textarea>
        </div>
        <v-btn :disabled="loadButton" @click="enviarFormulario() && dialog == true" block class="mr-4 btnMobile" type="button" style="
                        background-color: #D31F2C; 
                        color: #fff; 
                        width: 50px ;
                        font-family: Sofia pro, Roboto; 
                        font-size: 20px;
                        font-weight: 700;
                        padding: 25px 5px;
                        border-radius: 5px;
                        border:1px solid #646464 
                        text-transform: inherit; 
                        margin-top: 2px;
                        margin-bottom:35px">
          Enviar
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500" style="z-index: 99999 !important">
            <v-card class="bgCard">
                <v-card-title
                    style="color:#071c4a; font-weight: 500; font-size: 20px; font-family: Sofia pro; word-break:normal">
                    Sua sugestão foi enviada!
                </v-card-title>
                <v-card-text style="color:#071c4a; font-weight: 500; font-family: Sofia pro; word-break:normal">Obrigado,
                     entraremos em <br> contato em breve.</v-card-text>
                <v-card-actions>

                    <v-btn style="left:1px !important" color="#d31f2c" text @click= "dialog = false">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

  </v-container>
</template>

<script>
export default {
  data: () => ({
  
    dialog: false,
    loadButton: false,
    text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
    contato: { nome: "", email: "", unidade: "", telefone: "", mensagem: "", },
     items: ['Caçapava', 'Campinas', 'São José dos Campos - Jardim América', 'São José dos Campos - Aquarius', 'São José dos Campos - Vila Industrial',
      'São José dos Campos - Santana', 'São José dos Campos - Vista Verde', 'Taubaté - SP', 'São Sebastião - SP', 'Limeira - SP', 'Ribeirão Preto - SP', 'Piracicaba - SP', 'Nova Serrana - MG', 'São José do Rio Preto - SP', 'Niterói - RJ', 'Curitiba - PR', 'Vitória - ES', 'Fortaleza - CE'],
    timeout: 2000,
  }),

  methods: {
    enviarFormulario() {
      if ((this.contato.nome === "")) { 
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.email === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.unidade === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.telefone === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.mensagem === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else{
      this.loadButton = true,
        fetch("https://gestao.abare.cloud/items/leads", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ id_formulario: 70, lead: this.contato }),
        })
          .then((response) => response.text())
          .then((data) => {
            window.console.log(data);
            // alert("contato enviado com sucesso");
            this.dialog = true;
          });
    }
  }

  },
}
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 731px) {

  .padMobile {
    padding: 50px 0px !important;

  }

  .subtituloM{
    margin-left: 7px !important;
  }

  .textoTopo{
  font-size: 70px !important;
  font-weight: 900 !important;
  color: rgb(255, 255, 255) !important;
  opacity: 0.5 !important;
  margin-left: 0px !important;
  line-height: 59px !important;
  padding: 3px 3px !important;

  }
  .positionMobile {
    position: relative !important;
    right: 0 !important;
    top: initial !important;
    padding: 5px 5px !important;
    width: 340px !important;
  }

  .btnMobile {
    padding: 0px 40px !important;
    height: 50px !important;
    min-width: 150px !important;
    justify-content: center;
    margin-left: 95px !important;
  }
}


.textoTopo {
  font-size: 70px !important;
  font-weight: 900 !important;
  color: rgb(255, 255, 255) !important;
  opacity: 0.5 !important;
  margin-left: 100px;
  line-height: 59px !important;
  padding: 3px 3px !important;
}

.bgCard {
    background-image: url("../assets/sac.png");
    background-repeat: no-repeat !important;
    background-size: cover;
    width: 500px;
    background-attachment: inherit;
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center-top;
}

.pt5 {
  background-image: url("../assets/londres.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center;
}
</style>