<template>
	<v-container fluid>
		<v-row class="bgc1 d-flex align-center">
			<v-col>
				<H1 class="textoTopo" style="
						font-size: 100px;
						font-weight: 900;
						color: #fff;
						margin-top: 100px;
						opacity: 80%;
						line-height: 80px;
						padding: 3px 90px;
					">UNIDADES<br />HUGLE</H1>
			</v-col>
		</v-row>
		<!-- <v-row class=" align-center justify-center d-flex flex-column padMobile padLeftMobile">
			<v-col cols="12" md="12" class="align-center justify-center d-flex flex-column " data-aos="fade-right" data-aos-duration="2500">
				<h1 class="padMobile " style="color:#D31F2C; padding-top: 10px; padding-left: 80px; line-height: 35px;">Encontre a hugle mais próxima de você no brasil</h1>
				<div v-if="intBreakPoint > 2" id="vue-mapa">
					<mapa-brasil></mapa-brasil>
				</div>
			</v-col>
		</v-row> -->

		<v-row class="padTab fundoHugle" style="padding: 30px 60px; background-color: transparent !important">
			<v-col cols="12" md="12" class="fundoHugle">
				<v-card style="
						box-shadow: none !important;
						background-color: transparent !important;
					" class="fundoHugle">
					<v-tabs v-model="tabSelecionado" style="
							color: #0081bf !important;
							background-color: transparent !important;
						" class="padTab wdRow fundoHugle" centered>
						<v-tab class="wdTab fundoHugle" style="
								letter-spacing: 0px;
								text-transform: initial;
								font-family: 'Sofia Pro';
								border-radius: 50px;
								font-size: 18px;
								font-weight: bold;
							" v-for="(item, index) in estados" :key="index">
							{{ item }}</v-tab>

						<!-- <v-tab class="fundoHugle"
							style="letter-spacing: 0px; text-transform: initial; font-family: 'Sofia Pro'; border-radius: 50px; font-size: 18px; font-weight: bold; ">
							Espirito-Santo</v-tab> -->

						<v-tab-item v-for="n in estados" :key="n" style="background-color: transparent !important">
							<v-row style="padding: 30px 60px" class="fundoHugle pdRow">
								<v-col v-for="(unidade, i) in unidades[tabSelecionado]" :key="i" cols="12" md="4"
									style="background-color: transparent !important">
									<v-card style="
											min-height: 300px !important;
											box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.36) !important;
										" class="mx-auto wdCard bgc" max-width="344">
										<v-card-text>
											<p style="
													font-family: usual !important;
													font-size: 28px !important;
													color: #fff !important;
													font-weight: 700;
												" class="text-h4 text--primary">
												{{ unidade.nome }}
											</p>
											<div style="
													font-family: usual !important;
													color: #fff !important;
												" v-html="unidade.local" class="text--primary">
												<!-- {{unidade.local}} -->
											</div>
										</v-card-text>
										<v-card-actions style="position: absolute; bottom: 0px">
											<v-btn @click="abrirLink(unidade.whats)" class="wdBtn" style="
													letter-spacing: 0px;
													text-transform: initial;
													font-family: 'Sofia Pro';
													background-color: #41de5d;
													border-radius: 50px;
													font-size: 14px;
													font-weight: bold;
													font-size: 14px;
													color: #fff;
												">
												<v-icon size="22">mdi-whatsapp </v-icon>
												Entre em contato!
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
							</v-row>
						</v-tab-item>
					</v-tabs>
				</v-card>
			</v-col>
		</v-row>

		<!------------------------------------------------------------------------------------MOBILE---------------------------------------------------------------------------------->
		<!-- <div>
			<v-row v-if="intBreakPoint < 2" style="padding: 25px 0px">
				<v-col cols="12" md="12" data-aos="fade-right" data-aos-duration="2500">
					<v-card class="mx-auto" max-width="344">
						<v-card-title>
							Minas Gerais
						</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>

							<v-btn icon @click="show2 = !show2">
								<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
							</v-btn>
						</v-card-actions>
						<v-expand-transition>
							<div v-show="show2">
								<v-divider></v-divider>

								<v-card-text>
									<span style="font-weight:800">Nova Serrana</span> <br>
									<span style="font-weight:800">Endereço:</span> R. Prudente de Morais, 19 – Frei
									Paulo, CEP: 35519-000 <br>

									<span style="font-weight:800">Telefone:</span> 37 3228-8700 <br>

									<span style="font-weight:800">WhatsApp:</span> 37 99977-9905 <br>

									<span style="font-weight:800">E-mail:</span> novaserrana@@hugle.com.br <br>
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>

					<v-card class="mx-auto" max-width="344">
						<v-card-title>
							Paraná
						</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>

							<v-btn icon @click="show5 = !show5">
								<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
							</v-btn>
						</v-card-actions>
						<v-expand-transition>
							<div v-show="show5">
								<v-divider></v-divider>

								<v-card-text>
									<span style="font-weight:800">Curitiba</span> <br>
									<span style="font-weight:800">Telefone:</span> 41 9920-8426 <br>

									<span style="font-weight:800">WhatsApp:</span> 41 9920-8426 <br>

									<span style="font-weight:800">E-mail:</span> curitiba@hugle.com.br <br>
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>

					<v-card class="mx-auto" max-width="344">
						<v-card-title>
							Rio de Janeiro
						</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>

							<v-btn icon @click="show6 = !show6">
								<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
							</v-btn>
						</v-card-actions>
						<v-expand-transition>
							<div v-show="show6">
								<v-divider></v-divider>

								<v-card-text>
									<span style="font-weight:800">Niterói</span> <br>
									<span style="font-weight:800">Endereço:</span> R. Prudente de Morais, 19 – Frei
									Paulo, CEP: 35519-000 <br>

									<span style="font-weight:800">Telefone:</span> (21) 98212-2625 <br>

									<span style="font-weight:800">WhatsApp:</span> (12) 99653-6555 <br>

									<span style="font-weight:800">E-mail:</span> niteroi@hugle.com.br<br>
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>

					<v-card class="mx-auto" max-width="344">
						<v-card-title>
							Santa Catarina
						</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>

							<v-btn icon @click="show4 = !show4">
								<v-icon>{{ show4 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
							</v-btn>
						</v-card-actions>
						<v-expand-transition>
							<div v-show="show4">
								<v-divider></v-divider>

								<v-card-text>
									<span style="font-weight:800">Camboriú</span> <br>
									<span style="font-weight:800">Endereço: Camboriú, Rua Lauro Muller, 311 - Centro</span> <br>

									<span style="font-weight:800">Telefone:</span> 19 3259-1523<br>

									<span style="font-weight:800">WhatsApp:</span> <br>

									<span style="font-weight:800">E-mail:</span> <br>
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>

					<v-card class="mx-auto" max-width="344">
						<v-card-title>
							São Paulo
						</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn icon @click="show3 = !show3">
								<v-icon>{{ show3 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
							</v-btn>
						</v-card-actions>
						<v-expand-transition>
							<div v-show="show3">
								<v-divider></v-divider>
								<v-card-text>
									<span style="font-weight:800">SJC Parque Industrial</span> <br>
									<span style="font-weight:800">Endereço:</span> Avenida Guadalupe, 560 – Jardim
									América, CEP: 12235-000<br>

									<span style="font-weight:800">Telefone:</span> 12 3029-9090 <br>

									<span style="font-weight:800">WhatsApp:</span> 12 98207-0467 <br>

									<span style="font-weight:800">E-mail:</span> parqueindustrial@hugle.com.br <br>
								</v-card-text>

								<v-card-text>
									<span style="font-weight:800">SJC Jardim Aquarius</span> <br>
									<span style="font-weight:800">Endereço:</span> Av. Cassiano Ricardo, 521, 1º andar
									Aquarius Center – Jardim Aquarius, CEP: 12246-870<br>

									<span style="font-weight:800">Telefone:</span> 12 3906-6630 <br>

									<span style="font-weight:800">WhatsApp:</span> 12 99661-0866 <br>

									<span style="font-weight:800">E-mail:</span> aquarius@@hugle.com.br <br>
								</v-card-text>

								<v-card-text>
									<span style="font-weight:800">SJC Santana</span> <br>
									<span style="font-weight:800">Endereço:</span> Rua Guarani, 317 – Santana, CEP:
									12211-740<br>

									<span style="font-weight:800">Telefone:</span> 12 3917-2022 <br>

									<span style="font-weight:800">WhatsApp:</span>12 99141-1730 <br>

									<span style="font-weight:800">E-mail:</span>santana@hugle.com.br <br>
								</v-card-text>

								<v-card-text>
									<span style="font-weight:800">SJC Vila Industrial</span> <br>
									<span style="font-weight:800">Endereço:</span> Av. Pres. Juscelino Kubitschek, 6812
									– Vila Industrial, CEP: 12220-000<br>

									<span style="font-weight:800">Telefone:</span> 12 3902-2325 <br>

									<span style="font-weight:800">WhatsApp:</span> 12 98207-0468 <br>

									<span style="font-weight:800">E-mail:</span> vilaindustrial@hugle.com.br <br>
								</v-card-text>

								<v-card-text>
									<span style="font-weight:800">SJC Vista Verde</span> <br>
									<span style="font-weight:800">Endereço:</span> Av. Ilídio Meinberg Porto, 27 – Vista
									Verde, CEP: 12224-310<br>

									<span style="font-weight:800">Telefone:</span> 12 3902-1796 <br>

									<span style="font-weight:800">WhatsApp:</span> 12 98132-3113 <br>

									<span style="font-weight:800">E-mail:</span> vistaverde@hugle.com.br <br>
								</v-card-text>

								<v-card-text>
									<span style="font-weight:800">São Sebastião</span> <br>
									<span style="font-weight:800">Endereço:</span> Av. Guarda Mor Lobo Viana, 682 –
									Centro, CEP: 11608-530<br>

									<span style="font-weight:800">Telefone:</span> 12 3892-3262 <br>

									<span style="font-weight:800">WhatsApp:</span> 12 97408-6467 <br>

									<span style="font-weight:800">E-mail:</span> saosebastiao@hugle.com.br <br>
								</v-card-text>

								<v-card-text>
									<span style="font-weight:800">Taubaté </span> <br>
									<span style="font-weight:800">Endereço:</span> Rua Olavo Bilac, 96 – Centro, CEP:
									12020-310<br>

									<span style="font-weight:800">Telefone:</span> 12 3621-9087 <br>

									<span style="font-weight:800">WhatsApp:</span> 12 99683-0033 <br>

									<span style="font-weight:800">E-mail:</span> taubatecentro@hugle.com.br <br>
								</v-card-text>

								<v-card-text>
									<span style="font-weight:800">Caçapava </span> <br>
									<span style="font-weight:800">Endereço:</span> Rua Coronel José Guimarães, 49 - Vila Resende<br>

									
									<span style="font-weight:800">E-mail:</span> financeiro.cacapava@hugle.com.br <br>
								</v-card-text>

								<v-card-text>
									<span style="font-weight:800">Campinas </span> <br>
									<span style="font-weight:800">Endereço:</span> Rua Roberto Gomes Pedrosa, 294 - Vila Lemos<br>

									<span style="font-weight:800">Telefone:</span> 19 3259-1523 <br>


									<span style="font-weight:800">E-mail:</span> eric.morais@hugle.com.br <br>
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>

			</v-row>
		</div> -->
	</v-container>
</template>

<script>
import { mdiFacebook, mdiInstagram, mdiMapMarker } from "@mdi/js";
import { mdiMapMarkerCheckOutline } from "@mdi/js";
import { mdiWebCheck } from "@mdi/js";

export default {
	name: "Home",

	data: () => ({
		mdiFacebook,
		mdiInstagram,
		mdiMapMarker,
		mdiMapMarkerCheckOutline,
		mdiWebCheck,
		contato: { nome: "", email: "" },
		snackbar: false,
		tabSelecionado: 0,
		show1: false,
		show2: false,
		show5: false,
		show6: false,
		show3: false,
		show4: false,

		text: "Seu contato foi enviado! Em breve voce será notificado por e-mail.",
		cidade: {
			foto: "2cc0dc08-c715-4fee-90a9-1bafdfc84955",
		},
		slugCidade: "",
		estados: [
			"São Paulo",
			"Minas Gerais",
			"Rio de Janeiro",
			"Paraná",
			"Santa Catarina",
			"Espírito Santo",
			"Ceará",
		],
		unidades: [
			[
				{
					nome: "Limeira",
					local:
						"<b>E-mail:</b> limeira@hugle.com.br <br> <b>Telefone:</b> 12 99260-6836",
					whats: "https://api.whatsapp.com/send?phone=5512992606836",
				},
				{
					nome: "Ribeirão Preto",
					local:
						"<b>E-mail:</b> ribeirao@hugle.com.br <br> <b>Telefone:</b> 12 99260-6836 ",
					whats: "https://api.whatsapp.com/send?phone=5512992606836",
				},
				{
					nome: "SJC Parque Industrial",
					local:
						"SJC Parque Industrial, <br> Av. Guadalupe, 560 <br>  Parque Industrial, 12235-000 <br> <b>E-mail:</b> parqueindustrial@hugle.com.br <br> <b>Telefone:</b> 12 3029-9090",
					whats: "https://api.whatsapp.com/send?phone=5512982070467",
				},
				{
					nome: "SJC Jardim Aquarius",
					local:
						"SJC Jardim Aquarius, <br> Av. Cassiano Ricardo, 521 <br>  1º andar Aquarius Center, 12246-870 <br> <b>E-mail:</b> aquarius@hugle.com.br <br> <b>Telefone:</b> 12 3906-6630",
					whats: "https://api.whatsapp.com/send?phone=5512996610866",
				},
				{
					nome: "SJC Santana",
					local:
						"SJC Santana, <br> Rua Antônio Peneluppi Filho, 25 <br>  Santana, 12211-160 <br> <b>E-mail:</b> santana@hugle.com.br <br> <b>Telefone:</b> 12 3911-6999",
					whats: "https://api.whatsapp.com/send?phone=5512991465365",
				},
				{
					nome: "SJC Vila Industrial",
					local:
						"SJC Vila Industrial, <br> Av. Uberaba, 340 - ( Uberada Center)  <br>  Vila Industrial, 12220-740 <br> <b>E-mail:</b> vilaindustrial@hugle.com.br <br> <b>Telefone:</b> 12 3902-2325",
					whats: "https://api.whatsapp.com/send?phone=5512982070468",
				},
				{
					nome: "SJC Vista Verde",
					local:
						"SJC Vista Verde, <br> Av. Ilídio Meinberg Porto, 27<br>  Vista Verde, 12224-310 <br> <b>E-mail:</b> vistaverde@hugle.com.br <br> <b>Telefone:</b> 12 3021-1227",
					whats: "https://api.whatsapp.com/send?phone=5512981323113",
				},
				{
					nome: "São Sebastião ",
					local:
						"São Sebastião, <br> Av. Guarda Mor Lobo Viana, 682<br> 11608-530 <br> <b>E-mail:</b> saosebastiao@hugle.com.br <br> <b>Telefone:</b> 12 99260-6836",
					whats: "https://api.whatsapp.com/send?phone=5512974086467",
				},
				{
					nome: "Jacareí",
					local:
						"Rua General Carneiro, <br>394 Bairro Centro Jacareí - SP <br> CEP 12308-061 <br> <b>Telefone:</b> 12 99683-6416",
					whats: "https://api.whatsapp.com/send?phone=5512996836416",
				},
				{
					nome: "Taubaté",
					local:
						"Taubaté, <br> Rua Capitão Geraldo, 247<br> Centro, 12080-340 <br> <b>E-mail:</b> <br> <b>Telefone:</b> 12 99260-6836",
					whats: "https://api.whatsapp.com/send?phone=5512982640048",
				},
				// { nome: "Caçapava", local: "Caçapava, <br> Rua Coronel José Guimarães, 49<br> Vila Resende<br> <b>E-mail:</b> financeiro.cacapava@hugle.com.br <br> <b>Telefone:</b> ", whats: 'https://api.whatsapp.com/send?phone=5512991465365' },
				{
					nome: "Campinas",
					local:
						"Campinas, <br> Rua Roberto Gomes Pedrosa, 294<br> Vila Lemos<br> <b>E-mail:</b> quezia.morais@hugle.com.br <br> <b>Telefone:</b> 19 3259-1523",
					whats: "https://api.whatsapp.com/send?phone=5519989818148",
				},
				{
					nome: "Piracicaba",
					local: "<b>Telefone:</b> 12 99260-6836",
					whats: "https://api.whatsapp.com/send?phone=5512992606836",
				},
				{
					nome: "Rio Preto",
					local: "<b>Telefone:</b> 12 99260-6836",
					whats: "https://api.whatsapp.com/send?phone=5512992606836",
				},
			],
			[
				{
					nome: "Nova Serrana ",
					local:
						" Nova Serrana <br> R. Prudente de Morais, 19 - Frei Paulo <br> 35519-000<br> <b>E-mail:</b> novaserrana@hugle.com.br <br> <b>Telefone:</b> 37 3228-8700",
					whats: "https://api.whatsapp.com/send?phone=553791960343",
				},
			],
			[
				{
					nome: "Niteroi",
					local:

						"<b>E-mail:</b> niteroi@hugle.com.br <br> <b> Telefone:</b> (21) 96922-7448",
					whats: "https://api.whatsapp.com/send?phone=5521969227448",
				},
			],
			[
				{
					nome: "Curitiba",
					local:
						"<b>E-mail:</b> curitiba@hugle.com.br <br> <b>Telefone:</b> 12 99260-6836",
					whats: "https://api.whatsapp.com/send?phone=5512992606836",
				},
			],
			[
				{
					nome: "Camboriú",
					local:
						"Camboriú, <br> Rua Lauro Muller, 311<br> Centro<br> <b>E-mail:</b> <br> <b>Telefone:</b> 47 9 9966 0754",
					whats: "https://api.whatsapp.com/send?phone=5547999660754",
				},
			],
			[
				{
					nome: "Vila Velha",
					local:
						"<br>E-mail:</br> vilavelha@hugle.com.br <br> <b>Telefone:</b> (27) 99501-2544",
					whats: "https://api.whatsapp.com/send?phone=5527995012544",
				},
			],
			// [
			// 	{
			// 		nome: "Vitória",
			// 		local:
			// 			"<b>E-mail:</b> vitoria@hugle.com.br <br> <b>Telefone:</b> 12 99260-6836",
			// 		whats: "https://api.whatsapp.com/send?phone=5512992606836",
			// 	},
			// ],
			[
				{
					nome: "Fortaleza",
					local: "<b>E-mail:</b> fortaleza@hugle.com.br <br> <b>Telefone:</b> 12 99260-6836",
					whats: "https://api.whatsapp.com/send?phone=5512992606836",
				},
			],
		],
	}),

	created() {
		this.slugCidade = this.$route.params.slugCidade;
		fetch(
			"https://gestao.abare.cloud/items/tonelli_unidades?filter[slug][_eq]=" +
			this.slugCidade
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.data[0] != undefined) {
					this.cidade = data.data[0];
				}
			});
	},

	computed: {
		isHome() {
			return this.$route.name == "";
		},

		intBreakPoint() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 0;
				case "sm":
					return 1;
				case "md":
					return 2;
				case "lg":
					return 3;
				case "xl":
					return 4;
				default:
					return 0;
			}
		},
	},

	mounted() {
		window.scrollTo(0, 0);
	},

	methods: {
		goto(refName) {
			var element = this.$refs[refName];
			var top = element.offsetTop;
			window.scrollTo(0, top);
		},

		abrirLink(link) {
			window.open(link, "_blank");
		},

		enviarFormulario() {
			fetch("https://gestao.abare.cloud/items/leads", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({ id_formulario: 67, lead: this.contato }),
			})
				.then((response) => response.text())
				.then((data) => {
					window.console.log(data);
					// alert("contato enviado com sucesso");
					this.dialog = false;
					this.snackbar = true;
				});
		},
	},
};
</script>

<style scoped>
@media (min-device-width: 300px) and (max-device-width: 731px) {
	.bgLoja {
		height: 15vh !important;
	}

	.pdRow {
		padding: 30px 0px !important;
	}

	.padTab {
		padding: 0px 0px !important;
	}

	.textoTopo {
		font-size: 50px !important;
		line-height: 1 !important;
		padding: 0px !important;
		padding-top: 280px !important;
	}

	.wdRow {
		width: 350px !important;
	}

	.wdTab {
		min-width: 69px !important;
	}

	.wdBtn {
		width: 120px !important;
		font-size: 10px !important;
		margin-top: 20px !important;
	}

	.wdCard {
		width: 250px !important;
		height: 343px !important;
	}

	.padBtn {
		padding-left: 0px !important;
	}

	.heightMobile {
		height: auto !important;
	}

	.padMobile {
		padding: 5px 10px !important;
	}

	.footer {
		height: 280px !important;
	}

	.alturaMobile {
		height: auto !important;
	}

	.v-application--is-ltr .offset-3 {
		margin-left: 0;
	}
}

.v-slide-group__wrapper {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	flex-direction: column !important;
}

.btnCTA2 {
	background-color: #101414 !important;
	border-radius: 50px !important;
	box-shadow: none !important;
	font-family: Sofia Pro !important;
	padding: 25px 20px 25px 20px !important;
	font-size: 12px !important;
	text-align: left;
	color: #fff !important;
}

.v-tabs-items {
	background-color: transparent !important;
}

.bgc {
	background-image: url("../assets/bgCards.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.footer {
	background-color: rgb(20, 20, 20);
	height: 230px;
	margin-top: 25px;
}

.gmap_canvas {
	overflow: hidden;
	background: none !important;
	height: 300px;
	width: 600px;
}

.mapouter {
	position: relative;
	text-align: right;
	height: 300px;
	width: 600px;
}

.bannerLable {
	font-family: Sofia Pro;
	font-weight: 500;
	background-color: rgba(0, 0, 0, 0.562);
	color: #fff;
	padding: 20px 60px;
	font-size: 33px;
}

.bgc1 {
	background-image: url("../assets/brasil.webp");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	height: 500px;
}

.bgLoja {
	background-image: url("../assets/brasil.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 55vh;
}

.fundoHugle {
	background-image: url("../assets/capahugle.webp");
	background-repeat: no-repeat !important;
	background-size: cover;
	background-attachment: fixed;
	background-position: center-top;
}
</style>
